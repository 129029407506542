let video = null;
const addSourceToVideo = (element, src)=> {
    const source = document.createElement('source');
    source.src = src;
    source.type = 'video/mp4';
    element.appendChild(source);
}

const whichSizeVideo = (element, src) => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 800 ) {
        addSourceToVideo( element, src.dataset.desktopVid);
    } else {
        addSourceToVideo(element, src.dataset.mobileVid);
    }
}

const videoSize = () => {
    if (video !== null) {
        video.forEach(function(element) {
            whichSizeVideo(
                element,
                element
            );
        });
    }
}

document.addEventListener("DOMContentLoaded", function () {
    video =  document.querySelectorAll('.video-responsive');
    videoSize();
});
